<template>
  <!-- <a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="addCount"> -->
  <div class="d-flex justify-content-between align-items-center">
    <a-dropdown :trigger="['click']" placement="bottomRight" class="mr-3">
      <div style="position: relative; cursor: pointer">
        <a-icon type="bell" style="font-size: 20px; color: #428a94" />
        <div
          v-if="unreadCount"
          style="
            position: absolute;
            top: -5px;
            right: -5px;
            font-size: 10px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: #f5222e;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border: 1px solid white;
          "
        >
          {{ unreadCount > 9 ? "9+" : unreadCount }}
        </div>
      </div>
      <div slot="overlay">
        <div class="card" style="width: 448px; border-radius: 8px">
          <h6 class="m-3">Notifikasi</h6>
          <a-tabs
            default-active-key="1"
            @click.native.stop
            :class="$style.tabs"
            v-if="
              role === 'fileReviewer' || role === 'dokter' || role === 'koder'
            "
          >
            <a-tab-pane key="1" tab="Semua">
              <a-menu
                style="
                  border-right: none !important;
                  overflow-y: auto !important;
                  max-height: 600px;
                "
                v-if="notifications.length"
              >
                <a-menu-item
                  v-for="(notif, idx) in notifications"
                  :key="idx"
                  @click.native.stop
                  @click="handleDirect(notif)"
                  style="
                    white-space: wrap;
                    height: auto !important;
                    padding-bottom: 10px;
                    margin: 0 !important;
                    background-color: #f9fdff;
                  "
                  :style="{
                    backgroundColor: notif.isRead ? '' : '#f9fdff',
                    borderBottom: '1px solid #e5e5e5 !important',
                  }"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0" style="color: #428a94; font-size: 14px">
                      {{ notif.title }}
                    </p>
                    <p class="m-0" style="color: #667085; font-size: 14px">
                      {{ notif.relativeTime }}
                    </p>
                  </div>
                  <p class="m-0" style="line-height: normal">
                    {{ notif.description }}
                  </p>
                </a-menu-item>
              </a-menu>
              <div
                class="d-flex flex-column align-items-center justify-content-center py-2"
                v-else
              >
                <img
                  src="resources/images/empty-notif.png"
                  alt="avatar"
                  width="100"
                  height="100"
                />
                <p class="mt-2">Belum ada notifikasi</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Pemeriksaan Baru">
              <a-menu
                style="border-right: none !important"
                v-if="otherNotifications.length"
              >
                <a-menu-item
                  v-for="notif in otherNotifications"
                  :key="notif.id"
                  @click.native.stop
                  style="
                    white-space: wrap;
                    border-bottom: 1px solid #e5e5e5 !important;
                    height: 105px !important;
                    margin: 0 !important;
                  "
                  @click="handleDirect(notif)"
                  :style="{ backgroundColor: notif.isRead ? '' : '#f9fdff' }"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0" style="color: #428a94; font-size: 14px">
                      {{ notif.title }}
                    </p>
                    <p class="m-0" style="color: #667085; font-size: 14px">
                      {{ notif.relativeTime }}
                    </p>
                  </div>
                  <p class="m-0" style="line-height: normal">
                    {{ notif.description }}
                  </p>
                </a-menu-item>
              </a-menu>
              <div
                class="d-flex flex-column align-items-center justify-content-center py-2"
                v-else
              >
                <img
                  src="resources/images/empty-notif.png"
                  alt="avatar"
                  width="100"
                  height="100"
                />
                <p class="mt-2">Belum ada notifikasi</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Balasan Klarifikasi">
              <a-menu
                style="border-right: none !important"
                v-if="chatNotifications.length"
              >
                <a-menu-item
                  v-for="notif in chatNotifications"
                  :key="notif.id"
                  @click.native.stop
                  @click="handleDirect(notif)"
                  style="
                    white-space: wrap;
                    border-bottom: 1px solid #e5e5e5 !important;
                    height: 105px !important;
                    margin: 0 !important;
                  "
                  :style="{ backgroundColor: notif.isRead ? '' : '#f9fdff' }"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0" style="color: #428a94; font-size: 14px">
                      {{ notif.title }}
                    </p>
                    <p class="m-0" style="color: #667085; font-size: 14px">
                      {{ notif.relativeTime }}
                    </p>
                  </div>
                  <p class="m-0" style="line-height: normal">
                    {{ notif.description }}
                  </p>
                </a-menu-item>
              </a-menu>
              <div
                class="d-flex flex-column align-items-center justify-content-center py-2"
                v-else
              >
                <img
                  src="resources/images/empty-notif.png"
                  alt="avatar"
                  width="100"
                  height="100"
                />
                <p class="mt-2">Belum ada notifikasi</p>
              </div>
            </a-tab-pane>
          </a-tabs>
          <div v-else>
            <a-menu
              style="
                border-right: none !important;
                overflow-y: auto !important;
                max-height: 600px;
              "
              v-if="notifications.length"
            >
              <a-menu-item
                v-for="(notif, idx) in notifications"
                :key="idx"
                @click.native.stop
                @click="handleDirect(notif)"
                style="
                  white-space: wrap;
                  height: auto !important;
                  padding-bottom: 10px;
                  margin: 0 !important;
                  background-color: #f9fdff;
                "
                :style="{
                  backgroundColor: Number(notif.isRead) ? '' : '#f9fdff',
                  borderBottom: '1px solid #e5e5e5 !important',
                }"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0" style="color: #428a94; font-size: 14px">
                    {{ notif.title }}
                  </p>
                  <p class="m-0" style="color: #667085; font-size: 14px">
                    {{ notif.relativeTime }}
                  </p>
                </div>
                <p class="m-0" style="line-height: normal">
                  {{ notif.description }}
                </p>
              </a-menu-item>
            </a-menu>
            <div
              class="d-flex flex-column align-items-center justify-content-center py-2"
              v-else
            >
              <img
                src="resources/images/empty-notif.png"
                alt="avatar"
                width="100"
                height="100"
              />
              <p class="mt-2">Belum ada notifikasi</p>
            </div>
          </div>
          <div
            class="ml-3 my-3"
            @click="handleToNotification"
            style="cursor: pointer"
          >
            <p class="m-0" style="color: #428a94">Tampilkan Semua</p>
          </div>
        </div>
      </div>
      <!-- <a-menu style="width: 448px">
        <a-menu-item
          v-for="notif in notifications"
          :key="notif.id"
          @click.native.stop
          style="white-space: wrap"
        >
          <div class="p-2">
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0" style="color: #428a94; font-size: 14px">
                Komentar Klarifikasi Dibalas
              </p>
              <p class="m-0" style="color: #667085; font-size: 14px">
                23 menit yang lalu
              </p>
            </div>
            <p class="mt-2">
              Dr. Nur Fajar, Sp.Jp (DPJP) telah membalas komentar klarifikasi
              Anda. Mohon segera balas kembali atau tandai selesai
            </p>
          </div>
        </a-menu-item>
      </a-menu> -->
    </a-dropdown>
    <a-dropdown :trigger="['click']" placement="bottomLeft" class="mr-5">
      <div :class="$style.dropdown">
        <!-- <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" /> -->
        <div class="d-flex align-items-center">
          <div :class="$style.name">{{ this.role }}</div>
        </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="role in this.allRoles" :key="role">
          <a @click="changeRole(role)">
            {{ role }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown :trigger="['click']" placement="bottomLeft">
      <div :class="$style.dropdown">
        <!-- <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" /> -->
        <div class="d-flex align-items-center">
          <img
            src="resources/images/user-icon.svg"
            alt="avatar"
            width="25"
            height="25"
            :class="$style.avatar"
          />
          <div :class="$style.name">{{ this.user_name }}</div>
        </div>
      </div>
      <a-menu slot="overlay">
        <!-- <a-menu-item>
          <a href="javascript: void(0);" @click="() => { $router.push('/profile/account') }">
            <i class="fe fe-user mr-2"></i>
            {{ $t('topBar.profileMenu.editProfile') }}
          </a>
        </a-menu-item>
        <a-menu-divider /> -->
        <a-menu-item>
          <a href="javascript: void(0);" @click="logout">
            <i class="fe fe-log-out mr-2"></i>
            {{ $t("topBar.profileMenu.logout") }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex"
import {
  getAllNotification,
  readNotification,
} from "@/services/axios/api/notifications"

export default {
  computed: {
    ...mapState(["user"]),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      count: 7,
      user_name: this.$store.state.user.name,
      role: this.$store.state.user.role,
      allRoles: this.$store.state.user.allRoles,
      unreadCount: 0, // Jumlah notifikasi belum dibaca
      notifications: [],
      otherNotifications: [],
      chatNotifications: [],
    }
  },
  mounted() {
    this.getAllRole()
    this.getNotif()
  },
  methods: {
    handleToNotification() {
      this.$router.push("/notification")
    },
    async handleDirect(notif) {
      if (!notif.isRead) {
        await readNotification(notif.id)
      }
      this.getNotif()

      const currentPath = this.$route.path
      const targetRoute = this.$router.resolve(notif.directLink).route
      const fullUrl = `${window.location.origin}${
        this.$router.resolve(notif.directLink).href
      }`

      console.log("🌐 Target URL:", fullUrl)
      console.log("🔄 Current Path:", currentPath)
      console.log("🔄 Target Path:", targetRoute.path)

      if (currentPath === targetRoute.path) {
        console.log("🔄 Halaman sama, reload dengan query...")
        window.location.href = fullUrl
      } else {
        console.log("➡ Navigasi normal...")
        this.$router.push(notif.directLink).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error("❌ Error navigasi:", err)
          }
        })
      }
    },
    getRelativeTime(dateString) {
      const now = new Date()
      const date = new Date(dateString)

      const diffInMilliseconds = now - date
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
      const diffInMinutes = Math.floor(diffInSeconds / 60)
      const diffInHours = Math.floor(diffInMinutes / 60)
      const diffInDays = Math.floor(diffInHours / 24)

      if (diffInMinutes < 1) {
        return "baru saja"
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes} menit lalu`
      } else if (diffInDays < 1 && now.getDate() === date.getDate()) {
        return date.toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        })
      } else {
        return date.toLocaleDateString("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })
      }
    },
    async getNotif() {
      const { data } = await getAllNotification()
      this.notifications = data.data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Urutkan dari terbaru ke terlama
        .slice(0, 15) // Ambil hanya 20 notifikasi terbaru
        .map((e) => ({
          ...e,
          relativeTime: this.getRelativeTime(e.createdAt),
        }))
      this.chatNotifications = this.notifications.filter(
        (e) => e.isClarificationReply,
      )
      this.otherNotifications = this.notifications.filter(
        (e) => !e.isClarificationReply,
      )

      console.log(data.badges, "inibadges")
      this.unreadCount = data.data.filter((e) => !e.isRead).length
      // console.log(data.data, "lahtopbarini")
      // console.log(this.unreadCount, "ini??")
    },
    getAllRole() {
      this.allRoles = window.localStorage.getItem("allRoles").split(",")
    },
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch("user/LOGOUT")
    },
    changeRole(role) {
      const index = this.allRoles.indexOf(role)
      window.localStorage.setItem("role", this.allRoles[index])
      window.location.href = "/"
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
