import apiClient from "@/services/axios"

export const getAllNotification = async () => {
  const res = await apiClient.get("/api/notification")
  if (res) {
    return res
  } else {
    return null
  }
}
export const readNotification = async (id) => {
  const res = await apiClient.post("/api/notification/" + id)
  if (res) {
    return res
  } else {
    return null
  }
}
