<template>
  <div
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
    }"
  >
    <div :class="$style.logoContainer">
      <div :class="$style.logo">
        <img
          src="resources/images/medisync-withtext.svg"
          class="mr-2 mt-2"
          alt="Medisync"
          height="28"
        />
      </div>
    </div>
    <div
      :class="$style.navigation"
      class="d-flex align-items-center justify-content-between mr-4"
    >
      <a-menu
        :mode="'horizontal'"
        :selectedKeys="selectedKeys"
        @click="handleClick"
      >
        <template v-for="item in menuData">
          <template v-if="!item.roles || item.roles.includes(user.role)">
            <!-- {{ item.count }} -->
            <item
              v-if="!item.children && !item.category"
              :menu-info="item"
              :styles="$style"
              :key="item.key"
            />
            <sub-menu
              v-if="item.children"
              :menu-info="item"
              :styles="$style"
              :key="item.key"
            />
          </template>
        </template>
      </a-menu>
      <cui-user-menu />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import store from "store"
import find from "lodash/find"
import { getMenuData } from "@/services/menu"
import SubMenu from "./partials/submenu"
import Item from "./partials/item"
import CuiUserMenu from "@/components/cleanui/layout/Topbar/UserMenu"
import { getAllNotification } from "@/services/axios/api/notifications"

export default {
  name: "menu-top",
  components: { SubMenu, Item, CuiUserMenu },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    this.selectedKeys = store.get("app.menu.selectedKeys") || []
    this.setSelectedKeys()
    this.getNotif()
  },
  data() {
    return {
      menuData: [],
      selectedKeys: [],
      openKeys: [],
      badges: {},
    }
  },
  watch: {
    "settings.isMenuCollapsed"() {
      this.openKeys = []
    },
    $route() {
      this.setSelectedKeys()
    },
  },

  methods: {
    async getNotif() {
      const { data } = await getAllNotification()
      const role = localStorage.getItem("role")
      console.log(data.badges, "inibadges")
      // console.log(getMenuData[0], "iniallmenu")
      console.log(role, "inirole")

      this.menuData = getMenuData.map((e) => {
        let count = 0
        if (e.key === "Pemeriksaan Perawat" && role === "perawat") {
          count = data.badges.perawat
        }
        if (e.key === "Pemeriksaan Dokter" && role === "dokter") {
          count = data.badges.dokter
        }
        if (e.key === "Daftar Pemeriksaan Berkas" && role === "dokter") {
          count = data.badges.dokterKlarifikasi
        }
        if (e.key === "Pemeriksaan Koder" && role === "koder") {
          count = data.badges.koder
        }
        if (e.key === "Daftar Pemeriksaan Berkas" && role === "koder") {
          count = data.badges.koderKlarifikasi
        }
        if (e.key === "Lab Patologi Klinik" && role === "laborant") {
          count = data.badges.laborant
        }
        if (e.key === "Daftar Pemeriksaan Berkas" && role === "fileReviewer") {
          count = data.badges.fileReviewer
        }

        // ✅ Pakai Vue.set untuk memastikan reaktivitas
        this.$set(e, "count", count)

        return e
      })
      this.badges = data.badges
      // console.log(data.data, "lahtopbarini")
      // console.log(this.unreadCount, "ini??")
    },
    handleClick(e) {
      if (e.key === "settings") {
        this.$store.commit("CHANGE_SETTING", {
          setting: "isSettingsOpen",
          value: true,
        })
        return
      }
      store.set("app.menu.selectedKeys", [e.key])
      this.selectedKeys = [e.key]
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, "children"), [
        "url",
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
